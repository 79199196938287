:root {
    --window-color: #c0c0c0;
}

::-webkit-scrollbar {
    height: 12px;
    width: 6px;
    background: #aaa;
}

::-webkit-scrollbar-thumb {
    background: #333;
    border-radius: 1ex;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.75);
}

::-webkit-scrollbar-corner {
    background: #000;
}


.noselect {
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Safari */
    -khtml-user-select: none;
    /* Konqueror HTML */
    -moz-user-select: none;
    /* Old versions of Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.nodrag {
    -webkit-touch-callout: none;
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -ms-user-drag: none;
}

body {
    background: linear-gradient(135deg, #ff6, #f66);
    background-size: contain;
    background-repeat: no-repeat;
    font-family: 'Courier New', Courier, monospace;
    font-weight: lighter;
    overflow: hidden;
    width: 100%;
    height: 100vh;
    padding: 0;
    margin: 0;
}

.window {
    position: absolute;
    display: flex;
    flex-direction: column;
    background: #eee;
    border: 1px solid #3336;
    box-shadow: 0 2px 4px 2px #0006;
    overflow: hidden;
    cursor: nw-resize;
    border-radius: 5px;
}


.win-head {
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid #3333;
    background: var(--window-color);
    color: #fff;
    padding: 0.4rem 0;
}

.win-ops {
    width: fit-content;
    display: flex;
    flex-direction: row;
    padding: 0.2em 1.5rem;
    justify-content: center;
    align-items: center;
}

.win-ops .win-close {
    width: 0.51rem;
    height: 0.5rem;
    background: #f20;
    border-radius: 50%;
    border: 2px solid #333;
    padding: 0;
    margin: 0;
    transition: 300ms;
}

.win-close:hover, .win-close:active {
    filter: brightness(1.5);
    transform: scale(1.5);
}

.win-title {
    pointer-events: none;
    padding: 0.1em;
    width: 100%;
    text-align: center;
    font-weight: lighter;
    background: #fff;
    border: 1px solid #3333;
    color: #000;
    border-radius: 10px;
    margin-right: 1.5rem;
}

.win-title span::selection {
    background: none;
}

.win-body {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    background: #fff;
    scrollbar-width: thin;
}

.win-body * {
    font-size: 1em;
}

.page-heading {
    font-size: 2em;
    margin-bottom: 0.5em;
    font-weight: lighter;
    text-align: center;
}

.page-button {
    outline: none;
    border: 2px solid #222;
    background: #fff;
    padding: 0.5em 1em;
    transition: 100ms;
}

.page-button:hover, .page-button:active {
    border-color: #000;
    background: #222;
    color: #fff;
}

.page-button:active {
    background: #111;
    color: #eee;
}

.animate-window {
    animation-name: animateWindow;
    animation-duration: 2s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    animation-direction: alternate;
}

@keyframes animateWindow {
    0% {
        transform: translateY(0);
    }

    100% {
        transform: translateY(10px);
    }
}

.list {
    width: 100%;
}

.list ul {
    list-style: none;
    padding: 0 1em;
}

.list ul li {
    --bg: #fff;
    --brd: #1e90ff;
    --sdw: #1e90ff44;
    border: 2px solid var(--brd);
    padding: 0.5em 1em;
    margin: 0;
    margin-bottom: 1em;
    box-shadow: 0.5em 0.5em var(--sdw);
    color: var(--brd);
    background: var(--bg);
    transition: 300ms;
}

.list ul li:hover,  ul li:active {
    background: var(--brd);
    color: var(--bg);
    border-color: var(--brd);
    transform: translateY(-10%);
}

.list a {
    text-decoration: none;
}

.skills {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}

.cell {
    --c: #222;
    --sc: #2224;
    --bg: #fff;

    display: block;
    background: var(--bg);
    border: 2px solid var(--c);
    width: calc(50% - 1em - 4px - 1em);
    max-width: 10em;
    padding: 0.5em 0.5em;
    margin: 0.5em auto;
    box-shadow: 0.5em 0.5em var(--sc);
    transition: 300ms;
}

.cell:hover, 
.cell:active {
    background: #ff3;
    border-color: var(--c);
    box-shadow: 0.5em 0.5em #ff69b488;
    transform: translateY(-5%);
}

.profpic {
    filter: saturate(1.1);
    border: 4px solid #222;
    width: min(50%, 20rem);
    margin: 0 auto;
    transition: 300ms;
}

.profpic:hover, 
.profpic:active {
    filter: saturate(1.5);
    border-color: #ff3;
    box-shadow: 0.5em 0.5em #ff69b488;
}

.projects {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}

.projects .cell img {
    width: 100%;
    border: 2px solid #333;
    align-self: 1/1;
}

.projects .cell .cover {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
    height: 100%;
}

.projects .cell span.t {
    display: block;
    margin: auto;
}

.projects a {
    color: unset;
    text-decoration: none;
}

.tag {
    display: block;
    width: fit-content;
    font-weight: lighter;
    font-size: 0.7em;
    padding: 0.2em 0.5em;
    border-radius: 50%/20%;
    margin-right: auto;
}

.certs {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}

.certs .cell{
    max-width: 40%;
}

.certs .cell img {
    width: 100%;
    border: 2px solid #333;
    align-self: 1/1;
    background: #fff;
}

.certs .cell .cover {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
    height: 100%;
}

.certs .cell span.t {
    display: block;
    margin: auto;
}

.certs a {
    color: unset;
    text-decoration: none;
}